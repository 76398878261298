@import './settings.scss';
@import './typeographys.scss';

.pagination-container {
	font-family: $default;
	display: flex;
	list-style-type: none;

	.pagination-item {
		padding: 0 12px;
		height: 32px;
		text-align: center;
		margin: auto 4px;
		color: rgba(0, 0, 0, 0.87);
		display: flex;
		box-sizing: border-box;
		align-items: center;
		letter-spacing: 0.01071em;
		border-radius: 16px;
		line-height: 1.43;
		font-size: 14px;
		font-weight: 500;
		min-width: 32px;

		&.dots:hover {
			background-color: transparent;
			cursor: default;
		}
		&:hover {
			background-color: lighten($maroon, 75%);
			cursor: pointer;
		}

		&.selected {
			color: white;
			font-weight: 700;
			background-color: rgba(0, 0, 0, 0.08);
			background-color: $maroon;
		}

		.arrow {
			color: $maroon;
		}

		&.disabled {
			display: none;

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}
}

@media (hover: none) {
	.pagination-container {
		.pagination-item {
			&.dots:hover {
				background-color: transparent;
				cursor: default;
			}

			&:hover {
				background-color: transparent;
				cursor: default;
			}

			&.selected {
				color: white;
				font-weight: 700;
				background-color: rgba(0, 0, 0, 0.08);
				background-color: $maroon;
			}
		}
	}
}
