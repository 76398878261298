// Styles for the FAQs section

@import "../settings.scss";
@import "../typeographys.scss";

.disciplines {
  .evidence {
    font-weight: 600;
    list-style: none;
  }
}
