// Color palette

// Primary
$gold: #6a5638;
$maroon: #501215;
$light-maroon: #7a1b1e;
$lightest-maroon: #734143;

// Grayscale shades
$almost-black: #292724;
$almost-white: #ebebeb;
$callout-gray: #eaeaea;
$charcoal: #363534;
$dark-gray: #565656;
$light-gray: #d6d6d6;
$lightest-gray: #dddddd;
$background-wrap: #f5f1ee;

// Other colors
$light-gold: #d6cdb7;
$dark-sandstone: #ddd5c9;
$highlight: #f5e189;
$lightest-gold: #e1ddd7;
$red: #af081c;
$spring-lake-blue: #00507a;
$yellow: #ac9156;

// Specific stylings
$border: #ccc;
$callout-yellow: #ebba45;
