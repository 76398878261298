@import "../settings.scss";
@import "../typeographys.scss";

.home-page {
  display: flex;
  margin-top: 25px;
  a {
    color: black;
    flex: 1;
    text-decoration: none !important;
    margin: 10px auto;
    max-width: 500px;
  }

  a:visited {
    color: black;
  }

  .apply-consultant,
  .find-consultant {
    border-radius: 8px;
    border: 4px solid $border;
    color: white;
    margin: 0 auto;
    min-height: 250px;
    padding: 20px 20px 25px 20px;
    text-align: center;
    text-decoration: none;
    width: 75%;

    h2 {
      font-size: 30px;
      margin-bottom: 0px;
    }

    &.disabled {
      background: $light-gray;
      color: $dark-gray;
      cursor: default;
      margin-top: -1px;
    }
  }

  .apply-consultant {
    background: $maroon;
    #laptop {
      font-size: 5em;
    }
    .read-more a {
      color: $maroon;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .apply-consultant:hover,
  .find-consultant:hover {
    opacity: 0.9;

    &.disabled {
      opacity: 1;
    }
  }

  .find-consultant {
    background: $gold;
    position: relative;
    position: relative;
    display: inline-block;


    .coming-soon {
      --d: 8px;
      --g: 26px;
      --c: #00507a;

      background: linear-gradient(
            135deg,
            transparent var(--g),
            var(--c) calc(var(--g) - 0.3px)
          )
          left,
        linear-gradient(
            -135deg,
            transparent var(--g),
            var(--c) calc(var(--g) - 0.3px)
          )
          right;
      background-size: 51% 100%;
      background-repeat: no-repeat;
      clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        calc(100% - var(--d)) calc(100% - var(--d)),
        var(--d) calc(100% - var(--d)),
        0 100%
      );
      color: #fff;
      font-weight: 600;
      left: 0;
      padding: 5px 0 calc(var(--d) + 5px);
      position: absolute;
      text-align: center;
      top: 0;
      transform: translate(-12.71%, 321%) rotate(-45deg);
      transform-origin: top left;
      -webkit-clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        calc(100% - var(--d)) calc(100% - var(--d)),
        var(--d) calc(100% - var(--d)),
        0 100%
      );
      width: 200px;
    }

    #info {
      font-size: 5em;
    }

    .read-more a {
      color: $maroon;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
