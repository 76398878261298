@import '../settings.scss';
@import '../typeographys.scss';

.about {
	.embed-container {
		position: relative;
		padding-bottom: 80%;
		height: 0;
		max-width: 100%;

		iframe,
		object,
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	small {
		position: absolute;
		z-index: 40;
		bottom: 0;
		margin-bottom: -15px;
	}

	.appendix {
		border-collapse: collapse;
		width: 100%;
		margin: 30px auto;

		tr:nth-child(even) {
			background-color: lighten($dark-sandstone, 5%);
		}

		table,
		th,
		td {
			border: 1px solid;
			padding: 10px;
		}

		th {
			background: $maroon;
			color: #fff;
		}

		td {
			ul {
				li {
					font-size: 1rem;
				}
			}
		}
	}
}
