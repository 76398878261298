@import '../settings.scss';
@import '../typeographys.scss';

// Dashboard

.dashboard {
	.total {
		background-color: $maroon;
		border: 3px solid $border;
		color: $almost-white;
		flex: 1 1 100%;
		margin: 0.5rem;
		padding: 1rem;
		text-align: center;

		h3 {
			font-size: 1.5rem;
			margin: 8px auto;
		}
	}
	.dashboard-counts {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 1.5rem;
		margin-top: 1.5rem;

		.count {
			margin: 0.5rem;
			background-color: white;
			border-radius: 0.25rem;
			border: 1px solid $border;
			box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.1);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-content: space-between;
			padding: 1rem 1rem 0 1rem;
			text-align: center;
			width: 150px;
			align-items: center;

			h3 {
				color: $dark-gray;
				font-size: 1rem;
				font-weight: 400;
				margin-bottom: 0.5rem;
			}

			span {
				color: $maroon;
				font-weight: 600;
			}

			h4 {
				color: black;
				font-size: 1.5rem;
				font-weight: 400;
				margin: 10px auto;
			}

			.bar {
				display: flex;
			}
		}
	}
}
