@import './settings.scss';
@import './typeographys.scss';

/* Universal Styles */
* {
	font-family: Arial, Helvetica, sans-serif;
}

body {
	margin: 0px;
}

a {
	text-decoration: none;
}

a:visited {
	color: black;
}

#wrap {
	margin: 0 auto;
	min-height: 87vh;
	padding: 0px;
}

.env-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5em;
	line-height: 20px;
	padding: 5px 0;
	text-align: center;
	height: 40px;

	&.qual {
		color: #333;
		background-color: #ebba45;
		font-weight: bold;
		padding: 2px 4px;
		text-transform: uppercase;
		border-radius: 3px;
	}

	&.dev {
		color: #fff;
		background-color: #00507a;
	}
}

.no-scroll {
	overflow: hidden;
}

.announcement {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 1rem;
	background-color: #f9f9f9;
	border: 1px solid #ddd;
	border-radius: 0.5rem;

	i {
		font-size: 1.5rem;
		color: $maroon;
	}

	p {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
	}

	b {
		color: $maroon;
	}
}
