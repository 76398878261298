@import '../settings.scss';
@import '../typeographys.scss';

// /search Filters at the top of the page
.filters {
	background: $light-gray;
	border: 2px solid $border;
	border-radius: 2px;
	display: flex;
	flex-wrap: wrap;
	margin: 10px auto;
	padding: 20px;

	.refresh {
		float: right;
		margin-right: -5px;
	}

	.filter {
		border-radius: 2px;
		flex: 0 0 20%;
		justify-content: space-between;

		&.org {
			margin: 10px 0 0 0;
		}

		input,
		select {
			font-size: 14px;
			padding: 5px;
			width: 96%;
		}

		input {
			width: 85%;
		}

		input:focus-visible,
		select:focus-visible {
			outline-color: $spring-lake-blue;
		}

		.refresh,
		&.mobile-refresh {
			background: $spring-lake-blue;
			color: white;
			font-weight: 600;
			padding: 6px;
			text-align: center;
		}

		.refresh:hover,
		&.mobile-refresh:hover {
			color: $light-gray;
			cursor: pointer;
		}

		&.mobile-refresh {
			display: none;
			padding: 10px;
		}

		.date {
			color: $dark-gray;
			font-size: 12px;
			font-style: italic;
			margin-bottom: -10px;
			margin-top: 25px;
			text-align: right;
		}
	}
}

// Table of contractors styling
.contractors,
tr,
td,
th {
	border: 1px solid $border;
	border-collapse: collapse;
}

.contractors {
	background: white;
	padding: 20px;
	width: 100%;

	tbody {
		display: block;

		max-width: 100vw;

		th {
			background: $maroon;
			color: white;
			min-width: 200px;
			padding: 10px;
			width: 35%;
		}

		.name {
			width: 125px;
			cursor: pointer;
		}

		.table-phone {
			width: 75px;
		}

		.fa {
			cursor: pointer;
			margin-right: 5px;
			color: white;
		}

		.contractor {
			padding: 5px;

			td {
				padding: 10px;
			}
		}

		.contractor:hover,
		.contractor:nth-child(odd):hover {
			background: $highlight;
			cursor: pointer;
		}

		.contractor:nth-child(odd) {
			background: $lightest-gold;
		}
	}
}

// Individual Contractor stylings
.contractor-details {
	background-color: rgba(0, 0, 0, 0.4);
	height: 100%;
	left: 0;
	overflow: auto;
	padding-top: 50px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;

	/* Modal Content */
	.contractor-content {
		background-color: white;
		border: 1px solid $dark-gray;
		border-radius: 4px;
		height: auto;
		min-height: 80%;
		margin: auto;
		overflow: scroll;
		padding: 20px;
		width: 850px;

		// 'X' icon in top right corner of contractor details div
		.close {
			color: $border;
			float: right;
			font-size: 28px;
			font-weight: bold;
			margin-top: -5px;
		}

		.close:hover,
		.close:focus {
			color: $dark-gray;
			cursor: pointer;
			text-decoration: none;
		}

		.header {
			border-bottom: 1px solid $maroon;
			padding-bottom: 3px;

			span {
				font-style: italic;
				font-weight: 400;
			}
		}

		h2 {
			font-family: $default;
		}

		.fa-print {
			margin-right: 20px;
			float: right;
			font-size: 24px;
		}

		.fa-print:hover {
			cursor: pointer;
		}

		.contact-addl-container {
			display: flex;

			.contact,
			.additional-info {
				background: $lightest-gray;
				border-radius: 4px;
				flex: 1 1 45%;
				flex-wrap: wrap;
				margin-bottom: 20px;
				min-height: 150px;
				padding: 15px;

				p {
					font-size: 16px;
				}

				h3 {
					border-bottom: 1px solid gray;
					color: $maroon;
					margin-top: 0px;
				}

				.additional-details {
					margin-left: 5px;

					li {
						list-style-type: none;
						margin-left: 10px;
					}
				}

				h4 {
					margin: 5px 0px;

					span {
						font-weight: 400;
					}
				}
			}
			.contact {
				margin-right: 25px;

				.email,
				.phone,
				.address {
					margin: 5px;
					display: flex;
					align-items: flex-start;

					a {
						text-decoration: none;
						display: flex;
						align-items: center;

						.fa {
							font-size: 18px;
							min-width: 25px;
							text-align: center;
						}

						span {
							margin-top: 5px;
							margin-left: 10px;
							color: black;
							display: block;

							&:hover {
								text-decoration: underline;
							}
						}
					}

					a:hover {
						color: black;

						.fa {
							color: lighten($maroon, 10%);
							text-decoration: none !important;
						}
					}
				}
			}
		}

		// SBTA, EOP, Audit, Other Discipline information styling
		.services {
			h2 {
				margin-top: 0px;
				margin-bottom: 10px;
			}

			.service {
				background: $lightest-gold;
				border-radius: 4px;
				margin-bottom: 20px;
				padding: 15px;

				.discipline {
					border-bottom: 1px solid gray;
					margin-top: 0px;
					color: $maroon;
				}

				.service-details {
					margin-left: 20px;

					p {
						font-size: 16px;
						line-height: 1;
						margin: 5px 10px;
					}

					.training,
					.license,
					.experience {
						margin-bottom: 10px;
					}

					h4 {
						margin: 5px 0px;

						span {
							font-weight: 400;
						}
					}
				}
			}
		}
		// Civil or Pending Litigations Styling
		.litigations {
			.litigation {
				background: $lightest-gray;
				border-radius: 4px;
				margin-bottom: 20px;
				padding: 15px;

				.litigation-info {
					border-bottom: 1px solid gray;
					margin-top: 0px;
					color: $maroon;
					h3 {
						margin: 0;
					}
				}

				.litigation-details {
					p {
						font-size: 16px;
						line-height: 1;
						margin: 0px;
						font-style: italic;
					}
				}
			}
		}
	}
}

.no-results {
	color: $maroon;
	font-size: 18px;
	font-weight: 600;
	margin: 20px auto;
	padding: 10px;
	text-align: center;
}

.pagination-bar {
	padding: 0;
	justify-content: center;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh; /* Full height of the viewport */
	text-align: center;
	font-size: 1.5em; /* Larger text */
	color: #555; /* Dark grey text, you can choose a color that fits your design */
	font-family: 'Arial', sans-serif; /* Stylish font-family, you can choose your own */
	background: rgba(255, 255, 255, 0.8); /* Light white background */
}

/* Optional: Add an animation to the loading text */
@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}

.loading::after {
	content: '...'; /* Triple dot after loading text */
	animation: pulse 1s infinite ease-in-out;
}
