// Styles for the Contact section

@import '../settings.scss';
@import '../typeographys.scss';

.contact {
	.flex-container {
		display: flex;

		.flex-box {
			background: $maroon;
			border-radius: 8px;
			border: 4px solid $border;
			color: white;
			flex: 0 0 28%;
			margin: 10px auto;
			min-height: 200px;
			padding: 20px 20px 25px 20px;
			text-align: center;
			text-decoration: none;

			a {
				color: white;
			}

			a:hover {
				color: $light-gray;
			}

			.fa {
				margin-top: 15px;
				font-size: 45px;
			}
		}
	}

	//   <div key={member.id} className='card'>
	//   <div className='headshot'>
	//     <img src={member.image} alt={member.name} />
	//   </div>
	//   <div className='team-member-info'>
	//     <h3 className="team-member-name">{member.name}</h3>
	//     <h4 className="team-member-title">{member.title}</h4>
	//     <p className="team-member-email">
	//       <a href={`mailto:${member.email}`}>
	//         <i className='fa fa-envelope'></i>
	//         {member.email}
	//       </a>
	//     </p>
	//     <p className='team-member-phone'>
	//       <i className='fa fa-phone'></i>
	//       {member.phone}
	//     </p>
	//   </div>
	// </div>

	.business-cards {
		display: flex;

		justify-content: center;

		.card {
			background: $maroon;
			border-radius: 8px;
			border: 4px solid $border;
			color: $almost-black;

			margin: 20px auto;
			min-height: 200px;
			text-align: center;
			text-decoration: none;
			width: 350px;

			a {
				color: $almost-black;
			}

			a:hover {
				color: $light-maroon;
			}

			.fa {
				margin: 10px;
				font-size: 15px;
			}
		}

		.headshot {
			padding: 10px;
			img {
				border: 2px solid $border;
				border-radius: 50%;
				height: 150px;
				width: 150px;
			}
		}

		.team-member-info {
			background: $almost-white;

			padding: 5px;

			h3 {
				font-size: 1.5em;
				margin-top: 4px;
			}

			h4 {
				font-size: 1.2em;
				margin-top: 2px;
				margin-bottom: 4px;
			}

			p {
				margin-top: 10px;
				margin-bottom: 0;
			}
		}
	}
}
